@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}

.step-bar {
  transition: width 0.5s ease;
}
