.move-2 {
  animation: move2 2s infinite;
}

@keyframes move2 {
  0% {
    transform: rotate(-1deg) translateX(-2px) translateY(-2px);
  }
  50% {
    transform: rotate(1deg) translateX(2px) translateY(2px);
  }
  100% {
    transform: rotate(-1deg) translateX(-2px) translateY(-2px);
  }
}
